import React, { useState } from "react";
import BackgroundImage from "gatsby-background-image";
// @ts-ignore
import { getFluidGatsbyImage } from "gatsby-source-sanity";

// TODO: Make this global
const sanityConfig = {
  projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  dataset: process.env.GATSBY_SANITY_DATASET
};

export interface JournalEntryProps {
  data: {
    _key: string;
    backgroundImage: {
      _key: string;
      asset: {
        id: string;
      };
    };
    label: string;
    line1: string;
    line2: string;
  };
}

export const JournalEntry = ({ data }: JournalEntryProps) => {
  const { _key, backgroundImage, line1, line2, label } = data;

  let fluidProps;

  if (backgroundImage.asset.id && !/gif/.test(backgroundImage.asset.id)) {
    fluidProps = getFluidGatsbyImage(
      backgroundImage.asset.id,
      { maxWidth: 2400 },
      sanityConfig
    );
  }

  return (
    <BackgroundImage
      tag="section"
      id={_key}
      className="journal-entry"
      fluid={fluidProps}
    >
      <div className="journal-entry__content">
        <div className="h6-spectral-regular">{line1}</div>
        <input type="text"></input>
        <label>{label}</label>
        <div className="h6-spectral-regular">{line2}</div>
      </div>
    </BackgroundImage>
  );
};
