import React, { useState } from "react";
import { StandardText } from "src/components/global/standardText";
import { ChevronDown, ChevronUp } from "src/components/svgs";

export interface TextBlockAccordionProps {
  data: {
    _key: string;
    accordionDropDowns: Array<{
      _key: string;
      title: string;
      headline: string;
      content: any;
    }>;
  };
}

export const TextBlockAccordion = ({ data }: TextBlockAccordionProps) => {
  const { accordionDropDowns, _key } = data;

  return (
    <div id={_key} className="section-accordion ">
      {accordionDropDowns &&
        accordionDropDowns.map(item => {
          return <Box key={item._key} data={item} />;
        })}
    </div>
  );
};

const Box = ({ data }: any) => {
  const { title, headline, content } = data;
  const [open, setOpen] = useState(true);
  const toggle = () => {
    setOpen(!open);
  };
  return (
    <div className="__box">
      <div className="section-accordion__contents">
        <h1 className="p2-barlow-strong section-accordion__title">{title}</h1>
        <div>
          <p className="p2-barlow-italic">{headline}</p>
          {open ? (
            <StandardText
              className="section-accordion__headline p2-barlow"
              data={{ text: content }}
            />
          ) : (
            <div></div>
          )}
        </div>
      </div>
      {open ? (
        <ChevronUp onFunction={() => toggle()} className="__svg" />
      ) : (
        <ChevronDown onFunction={() => toggle()} className="__svg" />
      )}
    </div>
  );
};
