import React, { useCallback, useEffect, useMemo, useState } from "react";
import { StandardText } from "../global/standardText";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import {
  useAddItemToCart,
  useCartItems,
  useCartTotals,
  useCheckout,
  useRemoveItemFromCart,
  useStore,
  useUpdateItemsFromCart
} from "src/context/siteContext";
import { formatCurrency } from "src/utils/currency";
import { useDebouncedCallback } from "use-debounce";

export interface StoreCartProps {
  data: {
    _key: string;
    additionalDetails: any;
    cartMetadata: {
      additionalDetails: any;
      checkoutButton: string;
      subtotalLabel: string;
      title: string;
    };
    productDetails: {
      price: string;
      title: string;
    };
    quantityLabel: string;
    orderNotice: string;
  };
}

export const StoreCart = ({ data }: StoreCartProps) => {
  const {
    _key,
    cartMetadata: {
      subtotalLabel,
      checkoutButton,
      additionalDetails: additionalCartDetails
    },
    quantityLabel,
    orderNotice
  } = data;

  const cartItems = useCartItems();
  const updateItems = useUpdateItemsFromCart();
  const addItem = useAddItemToCart();
  const removeItem = useRemoveItemFromCart();
  const { subtotal } = useCartTotals();
  const { isUpdatingQuantity } = useStore();

  const { product } = useStaticQuery(graphql`
    query StoreCartQuery {
      product: shopifyProduct {
        availableForSale
        title
        description
        descriptionHtml
        handle
        id
        images {
          originalSrc
          localFile {
            childImageSharp {
              fluid(maxWidth: 500, quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        variants {
          priceNumber
          requiresShipping
          shopifyId
        }
      }
    }
  `);

  const quantity = useMemo(
    () => (cartItems.length ? cartItems[0].quantity : 0),
    [cartItems]
  );

  const goToCheckout = useCheckout();

  const addQuantity = useCallback(() => {
    // if there are no lineItems, add one
    if (!cartItems?.length) {
      addItem(product.variants[0].shopifyId, 1);
    } else if (cartItems.length === 1) {
      updateItems({
        id: cartItems[0].id,
        quantity: cartItems[0].quantity + 1
      });
    } else {
      // This should not be possible
    }
  }, [cartItems, product.variants, updateItems, addItem]);

  const decrementQuantity = useCallback(() => {
    // if there are no lineItems, add one
    if (cartItems.length !== 1) {
      return;
    }

    const quantity = cartItems[0]?.quantity;

    if (!quantity) {
      return;
    }

    if (quantity === 1) {
      // Remove the item from the cart
      removeItem(cartItems[0]?.id);
    } else {
      // Decrement the number of items in the cart
      updateItems({
        id: cartItems[0]?.id,
        quantity: cartItems[0]?.quantity - 1
      });
    }
  }, [cartItems, removeItem, updateItems]);

  const [typedQuantity, setTypedQuantity] = useState(quantity);

  useEffect(() => {
    if (Number(typedQuantity) !== quantity) {
      setTypedQuantity(quantity);
    }
    // We specifically want to _not_ react to typedQuantity changes, only the other way around
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantity]);

  const debouncedQuantityUpdate = useDebouncedCallback(
    (value: string | number) => {
      if (+value <= 0) {
        // Remove the item from the cart
        removeItem(cartItems[0]?.id);
      } else {
        // Item doesn't exist in cart yet
        if (!cartItems.length) {
          addItem(product.variants[0].shopifyId, +value);
        } else {
          updateItems({
            id: cartItems[0]?.id,
            quantity: +value
          });
        }
      }
    },
    400
  );

  const handleTypedQuantity = useCallback(
    value => {
      setTypedQuantity(value);

      if (isNaN(+value) || value === "") {
        return;
      }

      debouncedQuantityUpdate(value);
    },
    [debouncedQuantityUpdate]
  );

  return (
    <div id={_key} className="store-cart">
      <div className="store-cart__cart-container">
        <h2 className="h6-spectral-semi-bold">Cart</h2>
        <div className="p1-barlow">
          <em>{subtotalLabel}:</em>{" "}
          <strong>
            {isNaN(+subtotal) ? subtotal : formatCurrency(+subtotal)}
          </strong>
        </div>
        <StandardText
          className="p3-barlow"
          data={{ text: additionalCartDetails }}
        />
        <button
          type="button"
          onClick={() => goToCheckout()}
          className="btn btn--primary-white"
          disabled={isUpdatingQuantity || cartItems.length < 1}
        >
          {checkoutButton}&nbsp;&nbsp;&nbsp;▸
        </button>
      </div>
      <div className="store-cart__product-container">
        <div className="store-cart__image-container">
          <Img fluid={product.images[0].localFile.childImageSharp.fluid} />
        </div>
        <div>
          <h2 className="h9-spectral-semi-bold">{product.title}</h2>
          <div className="p1-barlow-semi-bold">
            {formatCurrency(product.variants[0].priceNumber)}
          </div>
          <div className="store-cart__quantity-box">
            <div>
              <label className="store-cart__quantity-label p2-barlow-strong">
                {quantityLabel}:
              </label>
              <button
                disabled={isUpdatingQuantity}
                type="button"
                className="store-cart__quantity-button"
                onClick={() => decrementQuantity()}
              >
                &ndash;
              </button>
              <input
                disabled={isUpdatingQuantity}
                className="store-cart__quantity-input"
                value={typedQuantity}
                onChange={e => handleTypedQuantity(e.target.value)}
              />
              <button
                disabled={isUpdatingQuantity}
                type="button"
                className="store-cart__quantity-button"
                onClick={() => addQuantity()}
              >
                +
              </button>
            </div>
          </div>
          <div>{orderNotice}</div>
        </div>
      </div>
    </div>
  );
};
