import React from "react";

export interface TextBlockTestimonialsProps {
  data: {
    anchor: string;
    testimonials: Array<{
      _key: string;
      author: string;
      quote: string;
    }>;
  };
}

export const TextBlockTestimonials = ({ data }: TextBlockTestimonialsProps) => {
  const { anchor, testimonials } = data;
  return (
    <div id={anchor} className="section-text-block-testimonials">
      {testimonials &&
        testimonials.map((item, index) => {
          return (
            <div key={item._key} className={`__testimonial-block `}>
              <div className={`__info position-${index}`}>
                <h1 className="h8-spectral-regular">{item.quote}</h1>
                <p className="p3-barlow-italic">{item.author}</p>
              </div>
            </div>
          );
        })}
    </div>
  );
};
