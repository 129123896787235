import React from "react";
import { Image } from "src/components/image";

export interface DoubleImageHeroProps {
  data: {
    doubleImage: Array<{
      _key: string;
      asset: {
        id: string;
        url: string;
      };
    }>;
  };
}

export const DoubleImagehero = ({ data }: DoubleImageHeroProps) => {
  const { doubleImage } = data;
  return (
    <div className="double-image-hero-container">
      {doubleImage &&
        doubleImage.map(item => {
          return (
            <Image
              key={item._key}
              imageId={item.asset.id}
              className="__image-max-height-55vh"
            />
          );
        })}
    </div>
  );
};
