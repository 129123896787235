import React from "react";

import {
  StandardText,
  StandardTextProps
} from "src/components/global/standardText";
import {
  ProductGrid,
  ProductGridProps
} from "src/components/global/productGrid";
import {
  NestedPages,
  NestedPagesProps
} from "src/components/global/nestedPages";
import { HeroVideo, HeroVideoProps } from "src/components/hero/heroVideo";
import {
  TextBlockGeneral,
  TextBlockGeneralProps
} from "./textBlocks/textBlockGeneral";
import { ImageHero, ImageHeroProps } from "./hero/imageHero";
import { DoubleImagehero, DoubleImageHeroProps } from "./hero/doubleImageHero";
import {
  MultiImageGallery,
  MultiImageGalleryProps
} from "./hero/multiImageGallery";
import {
  TextBlockTestimonials,
  TextBlockTestimonialsProps
} from "src/components/textBlocks/textBlockTestimonials";
import {
  TextBlockAccordion,
  TextBlockAccordionProps
} from "src/components/textBlocks/textBlockAccordion";
import {
  TextBlockFaq,
  TextBlockFaqProps
} from "src/components/textBlocks/textBlockFaq";
import {
  TextBlockAboutNewsletter,
  TextBlockAboutNewsletterProps
} from "src/components/textBlocks/textBlockAboutNewsletter";
import {
  JournalEntry,
  JournalEntryProps
} from "src/components/textBlocks/journalEntry";
import {
  StoreProduct,
  StoreProductProps
} from "src/components/store/storeProduct";
import { StoreCart, StoreCartProps } from "src/components/store/storeCart";
import {
  TextVisualBlockPhysicalFeatures,
  TextVisualBlockPhysicalFeaturesProps
} from "../components/textVisualBlocks/textVisualBlockPhysicalFeatures";
export const Modules = ({
  reactModule,
  type
}: {
  type: string;
  reactModule: any;
}) => {
  switch (type) {
    case "textBlockGeneral":
      return (
        <TextBlockGeneral data={reactModule as TextBlockGeneralProps["data"]} />
      );
    case "heroVideoComponent":
      return <HeroVideo data={reactModule as HeroVideoProps["data"]} />;
    case "standardText":
      return <StandardText data={reactModule as StandardTextProps["data"]} />;
    case "productGrid":
      return <ProductGrid data={reactModule as ProductGridProps["data"]} />;
    case "nestedPages":
      return <NestedPages data={reactModule as NestedPagesProps["data"]} />;
    case "imageHero":
      return <ImageHero data={reactModule as ImageHeroProps["data"]} />;
    case "imageDouble":
      return (
        <DoubleImagehero data={reactModule as DoubleImageHeroProps["data"]} />
      );
    case "multiImageGallery":
      return (
        <MultiImageGallery
          data={reactModule as MultiImageGalleryProps["data"]}
        />
      );
    case "textVisualBlockPhysicalFeatures":
      return (
        <TextVisualBlockPhysicalFeatures
          data={reactModule as TextVisualBlockPhysicalFeaturesProps["data"]}
        />
      );
    case "textBlockTestimonials":
      return (
        <TextBlockTestimonials
          data={reactModule as TextBlockTestimonialsProps["data"]}
        />
      );
    case "textBlockAccordion":
      return (
        <TextBlockAccordion
          data={reactModule as TextBlockAccordionProps["data"]}
        />
      );
    case "textBlockFaq":
      return <TextBlockFaq data={reactModule as TextBlockFaqProps["data"]} />;
    case "textBlockAboutNewsletter":
      return (
        <TextBlockAboutNewsletter
          data={reactModule as TextBlockAboutNewsletterProps["data"]}
        />
      );
    case "journalEntry":
      return <JournalEntry data={reactModule as JournalEntryProps["data"]} />;
    case "storeProduct":
      return <StoreProduct data={reactModule as StoreProductProps["data"]} />;
    case "storeCart":
      return <StoreCart data={reactModule as StoreCartProps["data"]} />;
    default:
      return <span style={{ color: "white" }}>{type}</span>;
  }
};
