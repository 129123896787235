import React from "react";
import { Image } from "src/components/image";

// textVisualBlockPhysicalFeatures

export interface TextVisualBlockPhysicalFeaturesProps {
  data: {
    blockColumns: any;
    image: {
      asset: {
        url: string;
        id: string;
      };
    };
  };
}

export const TextVisualBlockPhysicalFeatures = ({
  data
}: TextVisualBlockPhysicalFeaturesProps) => {
  const { blockColumns, image } = data;
  return (
    <div className="text-visual-block-phyiscal-features background-color-black">
      <div className="__feature-column">
        <Paragraph>{blockColumns[0].paragraphOne}</Paragraph>
        <Paragraph>{blockColumns[0].paragraphTwo}</Paragraph>
        <Paragraph>{blockColumns[1].paragraphOne}</Paragraph>
        <Paragraph>{blockColumns[1].paragraphTwo}</Paragraph>
        <Image className="__image" imageId={image.asset.id} />
      </div>
    </div>
  );
};

const Paragraph = props => {
  return <p className="text-color-white p2-barlow ">{props.children}</p>;
};
