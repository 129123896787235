import React, { useCallback, useState } from "react";
import { StandardText } from "../global/standardText";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { useProductQuantity } from "src/context/siteContext2";
import { formatCurrency } from "../../utils/currency";
import { navigate } from "gatsby";
import {
  useAddItemToCart,
  useCartItems,
  useRemoveItemFromCart,
  useUpdateItemsFromCart
} from "src/context/siteContext";

export interface StoreProductProps {
  data: {
    _key: string;
    addToCartButton: string;
    additionalDetails: any;
    productDetails: {
      description: string;
      price: string;
      title: string;
    };
    quantityLabel: string;
  };
}

const MIN_QUANTITY = 1;
const MAX_QUANTITY = 99;

export const StoreProduct = ({ data }: StoreProductProps) => {
  const {
    _key,
    productDetails: { title, price, description },
    additionalDetails,
    addToCartButton,
    quantityLabel
  } = data;

  const [quantityToAdd, setQuantityToAdd] = useState("1");

  const updateQuantity = useCallback(num => {
    if (num === "") {
      setQuantityToAdd("");
      return;
    }

    const newNum = Math.min(Math.max(+num, MIN_QUANTITY), MAX_QUANTITY);

    if (isNaN(newNum)) {
      return;
    }

    setQuantityToAdd(`${newNum}`);
  }, []);

  const removeItem = useRemoveItemFromCart();
  const addItem = useAddItemToCart();
  const updateItems = useUpdateItemsFromCart();
  const cartItems = useCartItems();

  const { product } = useStaticQuery(graphql`
    query StoreProductQuery {
      product: shopifyProduct {
        availableForSale
        title
        description
        descriptionHtml
        handle
        id
        images {
          originalSrc
          localFile {
            childImageSharp {
              fluid(maxWidth: 500, quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        variants {
          priceNumber
          requiresShipping
          shopifyId
        }
      }
    }
  `);

  const setQuantity = useCallback(
    (value: string | number) => {
      if (+value <= 0) {
        // Remove the item from the cart
        removeItem(cartItems[0]?.id);
      } else {
        // Item doesn't exist in cart yet
        if (!cartItems.length) {
          addItem(product.variants[0].shopifyId, +value);
        } else {
          updateItems({
            id: cartItems[0]?.id,
            quantity: +value
          });
        }
      }
    },
    [product.variants, addItem, cartItems, removeItem, updateItems]
  );

  const addAndGoToCart = useCallback(() => {
    setQuantity(isNaN(+quantityToAdd) ? 0 : +quantityToAdd);
    // Add to the cart and then go to it
    navigate("/cart/");
  }, [quantityToAdd, setQuantity]);

  return (
    <div id="buy" className="store-product">
      <div className="store-product__image-container">
        <Img fluid={product.images[0].localFile.childImageSharp.fluid} />
      </div>
      <div className="store-product__details-container">
        <h2 className="h9-spectral-semi-bold">{product.title}</h2>
        <div className="p1-barlow-semi-bold">
          {formatCurrency(product.variants[0].priceNumber)}
        </div>
        <div
          className="store-product__details p3-barlow"
          dangerouslySetInnerHTML={{ __html: product.descriptionHtml }}
        ></div>
        <div className="store-product__quantity-box">
          <div>
            <label className="store-product__quantity-label p2-barlow-strong">
              {quantityLabel}:
            </label>
            <button
              className="store-product__quantity-button"
              onClick={() =>
                setQuantityToAdd(x => `${Math.max(+x - 1, MIN_QUANTITY)}`)
              }
            >
              &ndash;
            </button>
            <input
              className="store-product__quantity-input"
              value={quantityToAdd}
              onChange={e => updateQuantity(e.target.value)}
            />
            <button
              className="store-product__quantity-button"
              onClick={() =>
                setQuantityToAdd(x => `${Math.min(+x + 1, MAX_QUANTITY)}`)
              }
            >
              +
            </button>
          </div>
          <button
            type="button"
            className="store-product__cart-button btn btn--primary-black"
            // className=""
            onClick={addAndGoToCart}
          >
            {addToCartButton}
          </button>
        </div>
        <StandardText
          className="p3-barlow"
          data={{ text: additionalDetails }}
        />
      </div>
    </div>
  );
};
