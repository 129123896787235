import React, { useState } from "react";
import { StandardText } from "src/components/global/standardText";
import { ChevronDown, ChevronUp } from "src/components/svgs";

export interface TextBlockFaqProps {
  data: {
    _key: string;
    headline: string;
    faqQuestions: Array<{
      _key: string;
      question: string;
      answer: any;
    }>;
  };
}

export const TextBlockFaq = ({ data }: TextBlockFaqProps) => {
  const { _key, headline, faqQuestions } = data;

  return (
    <div id="faq" className="section-faq">
      <h2>{headline}</h2>
      <div className="section-faq__container">
        {faqQuestions.map(({ _key, question, answer }) => (
          <div key={_key}>
            <div className="p3-barlow-strong">{question}</div>
            <StandardText className="p3-barlow" data={{ text: answer }} />
          </div>
        ))}
      </div>
    </div>
  );
};
