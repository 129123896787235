import React from "react";
import { Image } from "src/components/image";

export interface ImageHeroProps {
  data: {
    callToAction: string;
    isPositionRight: string;
    image: {
      asset: {
        url: string;
        assetId: string;
        id: string;
      };
    };
  };
}

export const ImageHero = ({ data }: ImageHeroProps) => {
  const { image, callToAction } = data;

  return (
    <div className="image-hero">
      <Image className={`max-height-45h`} imageId={image.asset.id} />
      <div className="__content">
        <p className="text-color-white h8-spectral-regular">{callToAction}</p>
      </div>
    </div>
  );
};
