import React from "react";
import { StandardText } from "src/components/global/standardText";

export interface TextBlockGeneralProps {
  data: {
    _key: string;
    alignTextToRight: boolean;
    anchor: string;
    content: any;
    fontColor: {
      colorlist: {
        title: string;
        value: string;
      };
    };
    FontColor: {
      colorlist: {
        title: string;
        value: string;
      };
    };
    BackgroundColor: {
      colorlist: {
        title: string;
        value: string;
      };
    };
  };
}

export const TextBlockGeneral = ({ data }: TextBlockGeneralProps) => {
  const {
    anchor,
    BackgroundColor,
    content,
    alignTextToRight,
    FontColor
  } = data;

  return (
    <div
      id={anchor}
      style={{
        backgroundColor: BackgroundColor.colorlist.value
      }}
      className={`text-block-general-container ${
        alignTextToRight ? "text-block-general-container--align-right" : ""
      }`}
    >
      <StandardText
        className={` ${BackgroundFontColorController(
          FontColor.colorlist.title
        )} p1-barlow __text ${alignTextToRight ? "__text--align-right" : ""} `}
        data={{ text: content }}
      />
    </div>
  );
};

const BackgroundFontColorController = (type: string) => {
  switch (type) {
    case "white":
      return "text-color-white";
    default:
      return "text-color-rich-black";
  }
};

const FontColorController = (type: string) => {
  switch (type) {
    case "":
      return null;
    default:
      return null;
  }
};
