import React, { useCallback } from "react";
import { Image } from "src/components/image";

export interface HeroVideoProps {
  data: {
    array: any;
    buttonText: string;
    callToAction: string;
    videoFile: {
      asset: {
        url: string;
      };
    };
  };
}

export const HeroVideo = ({ data }: HeroVideoProps) => {
  const { buttonText, callToAction, array } = data;

  const goToAnchor = useCallback(e => {
    e.preventDefault();
    e.stopPropagation();

    const id = e.target.getAttribute("href");
    const el = document.querySelector(id) as HTMLElement;

    if (!el) {
      return;
    }

    el.scrollIntoView({ behavior: "smooth" });

    return false;
  }, []);

  return (
    <div className="hero-video-container">
      <AssetController array={array} />
      <div className="__content">
        <p
          className="h5-spectral-regular text-color-white --call-to-action-text"
          style={{ marginBottom: 32 }}
        >
          {" "}
          {callToAction}{" "}
        </p>
        <a
          href="#intro"
          className="btn btn--primary-white"
          onClick={goToAnchor}
          style={{ textDecoration: "none" }}
        >
          {buttonText}
        </a>
      </div>
    </div>
  );
};

const AssetController = ({ array }) => {
  return array.map(item => {
    let { _type } = item;
    switch (_type) {
      case "image":
        return (
          <Image
            key={item.asset.id}
            className="__video"
            imageId={item.asset.id}
          />
        );
      case "file":
        return (
          <video
            key={item.asset.id}
            muted
            loop
            autoPlay
            playsInline
            className="__video"
            src={item.asset.id}
          />
        );
      default:
        return null;
    }
  });
};
