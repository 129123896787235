import React from "react";

import BlockContent from "@sanity/block-content-to-react";

import { Serializer } from "src/utils/serializer";

export interface StandardTextProps {
  data: {
    text: any[];
  };
  className?: string;
}

export const StandardText = ({ data, className }: StandardTextProps) => {
  const { text } = data;
  return (
    <div className={className}>
      <BlockContent blocks={text} serializers={Serializer} />
    </div>
  );
};
