import React from "react";
import { Image } from "src/components/image";
import { useLightbox, SRLWrapper } from "simple-react-lightbox";

export interface MultiImageGalleryProps {
  data: {
    images: Array<{
      _key: string;
      asset: {
        id: string;
        url: string;
      };
    }>;
    previewButtonText: string;
  };
}

export const MultiImageGallery = ({ data }: MultiImageGalleryProps) => {
  const { images, previewButtonText } = data;
  const firstImages = images.slice(0, -1);
  const [lastImage] = images.slice(-1);

  const { openLightbox } = useLightbox();

  return (
    <SRLWrapper
      options={{
        buttons: {
          showAutoplayButton: false,
          showDownloadButton: false,
          showFullscreenButton: false,
          showThumbnailsButton: false
        },
        settings: {
          lightboxTransitionSpeed: 0.1,
          lightboxTransitionTimingFunction: "easeInOut",
          slideTransitionSpeed: 0.1,
          slideTransitionTimingFunction: "easeInOut"
        }
      }}
    >
      <div className="multi-image-gallery">
        <div className="multi-image-gallery__hidden-pages">
          {firstImages.map(image => {
            return (
              <Image
                key={image._key}
                imageId={image.asset.id}
                className="multi-image-gallery__image-container"
              />
            );
          })}
        </div>
        <div className="multi-image-gallery__preview-page">
          <Image
            imageId={lastImage.asset.id}
            className="multi-image-gallery__image-container"
          />
        </div>
        <button
          className="multi-image-gallery__preview-button btn btn--primary-black"
          // className=" p2-barlow-strong"
          onClick={() => openLightbox()}
        >
          {previewButtonText}
        </button>
      </div>
    </SRLWrapper>
  );
};
