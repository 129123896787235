import React, { useCallback, useState } from "react";
import { StandardText } from "src/components/global/standardText";
import { subscribe } from "klaviyo-subscribe";

// Found in Klaviyo
const LIST_ID = "RqGH72";

export interface TextBlockAboutNewsletterProps {
  data: {
    _key: string;
    newsletterHeadline: string;
    newsletterBlurb: any;
    newsletterEmailPlaceholder: string;
    newsletterEmailLabel: string;
    newsletterEmailSubmit: string;
    newsletterEmailSubmitted: string;
    aboutHeadline: string;
    aboutBlurb: any;
  };
}

const CheckmarkIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      fill="none"
      viewBox="0 0 33 32"
    >
      <g clipPath="url(#clip0)">
        <path
          fill="#0C0C0C"
          d="M16.25 26.825c-5.523 0-10-4.477-10-10s4.477-10 10-10 10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 100-16 8 8 0 000 16zm-.997-4l-4.243-4.243 1.414-1.414 2.829 2.83 5.656-5.658 1.415 1.414-7.071 7.071z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0">
          <path
            fill="#fff"
            d="M0 0H21V21H0z"
            transform="translate(5.5 6)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export const TextBlockAboutNewsletter = ({
  data
}: TextBlockAboutNewsletterProps) => {
  const {
    _key,
    newsletterHeadline,
    newsletterBlurb,
    newsletterEmailPlaceholder,
    newsletterEmailLabel,
    newsletterEmailSubmit,
    newsletterEmailSubmitted,
    aboutHeadline,
    aboutBlurb
  } = data;

  const [email, setEmail] = useState("");
  const [done, setDone] = useState(false);

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();

      subscribe(LIST_ID, email, {
        // optional traits go here
      }).then((response: any) => {
        setDone(true);
      });

      return false;
    },
    [email]
  );

  return (
    <div className="section-about-newsletter">
      <div className="section-about-newsletter__newsletter">
        <h2>{newsletterHeadline}</h2>
        <StandardText className="p3-barlow" data={{ text: newsletterBlurb }} />
        <form
          onSubmit={handleSubmit}
          className="section-about-newsletter__email-form"
        >
          <div className="section-about-newsletter__email-box">
            <input
              id={`${_key}-email-input`}
              value={email}
              onChange={e => setEmail(e.target.value)}
              type="email"
              className="p1-barlow"
              readOnly={done}
            />
            {done ? (
              <span className="p2-barlow section-about-newsletter__email-subscribed">
                {newsletterEmailSubmitted}
                <CheckmarkIcon />
              </span>
            ) : (
              <button className="p2-barlow" type="submit">
                {newsletterEmailSubmit}
              </button>
            )}
          </div>
          <label htmlFor={`${_key}-email-input`}>{newsletterEmailLabel}</label>
        </form>
      </div>
      <div className="section-about-newsletter__about" id="about">
        <h2>{aboutHeadline}</h2>
        <StandardText className="p3-barlow" data={{ text: aboutBlurb }} />
      </div>
    </div>
  );
};
